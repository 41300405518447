import { useState, useContext } from 'react';
import Searchbar from '../../components/Searchbar/Searchbar';
import Tablerekapitulacijaartikala from '../../components/Tablerekapitulacijaartikala/Tablerekapitulacijaartikala';
import IzabraniPartner from '../../components/IzabraniPartner/IzabraniPartner';
import PartnerContext from '../../context/PartnerContext/PartnerContext';
import Footer from '../../components/Footer/Footer';
import { Alert, Spinner, Container } from "react-bootstrap";

const RekapitulacijaArtikala = (props) => {

  const context = useContext(PartnerContext);

  const [vrtim, setVrtim] = useState({"naEkranu":true, "nazivKlase":"overlay d-flex justify-content-center align-items-center m-1 "});
  const updateSpinner = (input) => {
    if (input) {
      setVrtim({"naEkranu":true, "nazivKlase":"overlay d-flex justify-content-center align-items-center m-1 "});
    }
    else {
      setVrtim({"naEkranu":false, "nazivKlase":"overlay d-flex justify-content-center align-items-center m-1 d-none "});
    }
  }

  const [bezServera, setBezServera] = useState({"poruka":"", "nazivKlase":"text-center  d-none"});
  const updatebezServera = (input) => {
    if (input.bezServera) {
     setBezServera({"poruka":input.poruka, "nazivKlase":"text-center"});
    }
    else {
      setBezServera({"poruka":"", "nazivKlase":"text-center d-none"});
    }
  }

  const [filterData, setFilterData] = useState();
  const updateFilterData = (input) => {
     setFilterData(input);
  }

  return (
    <Container>
      <IzabraniPartner
        izabraniPartner= { context }
      />

      <Alert variant="danger" className={bezServera.nazivKlase}>
          {bezServera.poruka}
      </Alert>

      <div className={vrtim.nazivKlase}>
        <Spinner animation="border"  ></Spinner>
      </div>

      <Tablerekapitulacijaartikala
         filterTable = { filterData }
         onSpinerAktivan={ updateSpinner }
         podaci = {props.podaci}
         onBezServera={ updatebezServera }
      />
      <Footer
      />
    </Container>
  );
}

export default RekapitulacijaArtikala;
