import { useState, useContext } from 'react';
import Searchbar from '../../components/Searchbar/Searchbar';
import Tablepartneri from '../../components/Tablepartneri/Tablepartneri';
import IzabraniPartner from '../../components/IzabraniPartner/IzabraniPartner';
import PartnerContext from '../../context/PartnerContext/PartnerContext';
import Footer from '../../components/Footer/Footer';
import { Alert, Spinner } from "react-bootstrap";

const Partneri = (props) => {
  const context = useContext(PartnerContext);

  const [vrtim, setVrtim] = useState({"naEkranu":true, "nazivKlase":"overlay d-flex justify-content-center align-items-center m-1 "});
  const updateSpinner = (input) => {
     if (input) {
       setVrtim({"naEkranu":true, "nazivKlase":"overlay d-flex justify-content-center align-items-center m-1 "});
     }
     else {
       setVrtim({"naEkranu":false, "nazivKlase":"overlay d-flex justify-content-center align-items-center m-1 d-none "});
     }
  }

  const [bezServera, setBezServera] = useState({"poruka":"", "nazivKlase":"text-center  d-none"});
  const updatebezServera = (input) => {
    if (input.bezServera) {
     setBezServera({"poruka":input.poruka, "nazivKlase":"text-center"});
    }
    else {
      setBezServera({"poruka":"", "nazivKlase":"text-center d-none"});
    }
  }

  const [filterData, setFilterData] = useState();
  const updateFilterData = (input) => {
     setFilterData(input);
  }

  var izabraniPartner = context;
  const promjenaPartnera = (input) => {

     // kada se mjenja partner izbriši i cjenike jer mogu biti vezani za partnera
     localStorage.removeItem("cjenik_1_podaci");
     localStorage.removeItem("cjenik_1_vrijeme");
     localStorage.removeItem("cjenik_2_podaci");
     localStorage.removeItem("cjenik_2_vrijeme");

     props.globPromjenaPartnera(input);
     izabraniPartner = input;
  }

  return (

      <div>
        <IzabraniPartner
          izabraniPartner= { izabraniPartner }
        />

        <Alert variant="danger" className={bezServera.nazivKlase}>
            {bezServera.poruka}
        </Alert>

        <div className={vrtim.nazivKlase}>
          <Spinner animation="border"  ></Spinner>
        </div>

        <Searchbar
           onChange={ updateFilterData }
           tekstTrazenje="Šifra / naziv partnera"
        />
        <Tablepartneri
           filterTable = { filterData }
           promjenaPartnera= { promjenaPartnera }
           izabraniPartner= { izabraniPartner }
           onSpinerAktivan={ updateSpinner }
           onBezServera={ updatebezServera }
        />

        <Footer
        />

      </div>

  );
}

export default Partneri;
