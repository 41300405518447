import {useEffect, useState, useContext} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from "react-bootstrap";
import AuthContext from '../../context/AuthContext/AuthContext';

const Tablerekapitulacijaartikala = (props) => {


  const dataDobavljaci = [

	     {
		      sifra:"001", naziv:"PROBNO IME 1", iznos:"100,00",
		      artikli:[{sifra:"01", naziv:"COCA COLA", kolicina:"12,90", cijena:"209,09", iznos:"10090,99"}, {sifra:"02", naziv:"KOKTA", kolicina:"1,10"}, {sifra:"03", naziv:"SPRITE", kolicina:"22"}]
	     },
		   {
          sifra:"002", naziv:"PROBNO IME 2", iznos:"200,00",
		      artikli:[{sifra:"21", naziv:"COCA COLA 1L", kolicina:"1,90"}, {sifra:"22", naziv:"KOKTA 1L", kolicina:"2,10"}, {sifra:"23", naziv:"SPRITE 1L", kolicina:"32"}]
	     },
		   {
          sifra:"003", naziv:"PROBNO IME 3", iznos:"300,00",
		      artikli:[{sifra:"31", naziv:"COCA COLA 2L", kolicina:"2,90"}, {sifra:"32", naziv:"KOKTA 2L", kolicina:"3,10"}, {sifra:"33", naziv:"SPRITE 2L", kolicina:"42"}]
	     }


  ];

  const columnsDobavljaci = [
    {
      dataField: 'sifra',
      text: 'Šifra',
      style: { fontSize: '12px', textAlign: 'left'},
      headerStyle: { overflowWrap: 'break-word', fontSize: '12px'}
    },
    {
      dataField: 'naziv',
      text: 'Naziv dobavljača',
      style: { fontSize: '13px', textAlign: 'left', fontWeight: 'bold'},
      headerStyle: { overflowWrap: 'break-word', fontSize: '13px'}
    },
    {
      dataField: 'iznos',
      text: 'Prod.vr.',
      style: { fontSize: '10px', textAlign: 'right'},
      headerStyle: { textAlign: 'right',  overflowWrap: 'break-word', fontSize: '11px'}
    }
  ];

  const columnsArtikli = [
    {
      dataField: 'sifra_artikla',
      text: 'Šifra',
      style: { fontSize: '12px', textAlign: 'left', backgroundColor:'#e6ffff'},
      headerStyle: { width: '60px', overflowWrap: 'break-word', fontSize: '12px', backgroundColor:'#cfffff'}
    },
    {
      dataField: 'naziv_artikla',
      text: 'Naziv artikla',
      style: { fontSize: '13px', textAlign: 'left', backgroundColor:'#e6ffff'},
      headerStyle: { width: '100px', overflowWrap: 'break-word', fontSize: '12px', backgroundColor:'#cfffff'}
    },
    {
      dataField: 'kolicina',
      text: 'Količina',
      style: { fontSize: '10px', textAlign: 'right', backgroundColor:'#e6ffff'},
      headerStyle: { width: '40px', textAlign: 'right',  overflowWrap: 'break-word', fontSize: '11px', backgroundColor:'#cfffff'}
    },
    {
      dataField: 'cijena',
      text: 'VPC',
      style: { fontSize: '10px', textAlign: 'right', backgroundColor:'#e6ffff'},
      headerStyle: { width: '40px', textAlign: 'right',  overflowWrap: 'break-word', fontSize: '11px', backgroundColor:'#cfffff'}
    },
    {
      dataField: 'iznos',
      text: 'Prod.vr.',
      style: { fontSize: '10px', textAlign: 'right', backgroundColor:'#e6ffff'},
      headerStyle: { width: '40px', textAlign: 'right',  overflowWrap: 'break-word', fontSize: '11px', backgroundColor:'#cfffff'}
    }
  ];

  const contextAuth = useContext(AuthContext);
  const [dobavljaci, setDobavljaci] = useState([]);
  const ws_opcija = props.podaci.opcija;

  const napraviId = () => {
      var text = ''
      var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      for (var i = 0; i < 6; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      return text
  }


  const expandRow = {
    renderer: row => (
      <BootstrapTable keyField='sifra' data={ row.artikli } columns={ columnsArtikli } />
    ),
    showExpandColumn: true,
    expandColumnPosition: 'right'
  };

  const expandRow2 = {
    renderer: row =>
      <div>
        <p>Šifra</p>
        <p>Naziv</p>
        {row.artikli.map(artikal =>
                  <li key={artikal.sifra}>{artikal.naziv}</li>
                )}
      </div>
  };

  // pokrećem kada se promjeni opcija pregleda cjenika 1 ili 2
  useEffect(() => {

    // BEGIN testno okruženje
    /*
    props.onSpinerAktivan(false);
    setDobavljaci(dataDobavljaci);
    */
    // END testno okruženje

    // privremeno isključi zbog testiranja

    props.onSpinerAktivan(true);

    // BITNO
    // ovako, kada radim refresh contextPartnera uzima tek kasnije i prvo radi ovo a tu moramu uzeti partnera koji je izabran iz localStorage
    var p = JSON.parse(localStorage.getItem('izabraniPartner'));
    if (!p) p = {"konto_sifra":"", "naziv":""};

    const ws_connection = new WebSocket('ws://' + contextAuth.dns_server +'/');
    const initWs = () => {
         // šalji podatke kada se otvori socket
         // param3 : šifra partnera
         ws_connection.onopen = function (event) {
           let ws_send = {'oib': contextAuth.firma_oib, 'poslovniprostor': contextAuth.poslovni_prostor, 'kasa': '1', 'opcija': ws_opcija, 'param1' : '', 'param2' : '', 'param3' : p.konto_sifra, 'id': napraviId(), 'vrijeme_timestamp': 0}
           ws_connection.send(JSON.stringify(ws_send), function ack (error) {
             console.log('Greška : ' + error);
             props.onBezServera({'bezServera':true, 'poruka':'Ne mogu se spojiti na Pakomov server!'});
           })
         }

         ws_connection.onmessage = function (event) {

           this.ws_podaci = event.data
           const messageArray = JSON.parse(this.ws_podaci);
           ws_connection.close();

           if (messageArray.id === 'nema') props.onBezServera({'bezServera':true, 'poruka':'Ne mogu se spojiti na Orbisov server!'});
           setDobavljaci(messageArray.ODGOVOR1);
         }

         ws_connection.onclose = function (evt) {
          if (evt.wasClean) {
             console.log('ws zatvoren normalno');
           } else {
             console.log('ws greška u spajanju');
             props.onBezServera({'bezServera':true, 'poruka':'Greška u spajanju na server!'});
           }

           props.onSpinerAktivan(false);
         }

    };

    initWs();

    return () => {
      ws_connection.close();
    }


  }, [ws_opcija]);

  const pagination = paginationFactory({
      page: 1,
      sizePerPage: 2000,
      lastPageText: '>>',
      firstPageText: '<<',
      nextPageText: '>',
      prePageText: '<',
      showTotal: false,
      alwaysShowAllBtns: true,
      hideSizePerPage: true,
    });

  return (
    <Container>
      <BootstrapTable keyField='sifra' data={ dobavljaci } columns={ columnsDobavljaci } pagination={pagination}  expandRow={expandRow} />
    </Container>
  )
}

export default Tablerekapitulacijaartikala;
