import {useEffect, useState, useContext} from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Modal, Button } from "react-bootstrap";
import AuthContext from '../../context/AuthContext/AuthContext';

const Tablepartneri = (props) => {

  function iznosiFormatter(cell, row, rowIndex) {
    return (
      <div>
      {row.clcdospjelo}
      <br />
      {row.clcnedospjelo}
      <br />
      <strong>{row.clcukupno}</strong>
      </div>
    );
  }

  function iznosiHeaderFormatter(column, colIndex, components) {
    return (
      <div>
      Dospjelo
      <br />
      Nedospjelo
      <br />
      Ukupno
      </div>
    );
  }

  const columnsPartneri = [

    {
      dataField: 'konto_sifra',
      text: 'Šifra',
      style: { fontSize: '12px', textAlign: 'left'},
      headerStyle: { width: '60px', overflowWrap: 'break-word', fontSize: '12px'}
    },
    {
      dataField: 'naziv',
      text: 'Naziv',
      style: { fontSize: '13px', textAlign: 'left'},
      headerStyle: { width: '100px', overflowWrap: 'break-word', fontSize: '13px'}
    },
    {
      dataField: 'df1',
      text: '',
      isDummyField: true,
      style: { fontSize: '10px', textAlign: 'right'},
      headerStyle: { width: '60px', textAlign: 'right',  overflowWrap: 'break-word', fontSize: '10px'},
      formatter: iznosiFormatter,
      headerFormatter: iznosiHeaderFormatter
    }

  ];

  const context = useContext(AuthContext);

  const [showModal, setShowModal] = useState({ show: false, partner: ''});
  const handleClose = () => setShowModal({ show: false, partner: ''});

  const [partneri, setPartneri] = useState([]);
  const [partneriSvi, setPartneriSvi] = useState([]);

  const napraviId = () => {
      var text = ''
      var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      for (var i = 0; i < 6; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      return text
  }

    // pokrećem svaki put kada se ukuca tekst za pretraživanje partnera
    useEffect(() => {
      // napravi kopiju originalnih podataka i filtriraj
      const partneriFilter = [...partneriSvi];
      if (typeof(props.filterTable) != "undefined") {
        var a = partneriFilter.filter(function (el)  {
          return el.naziv.toLowerCase().indexOf(props.filterTable.Tekst.toLowerCase()) !== -1 || el.konto_sifra.indexOf(props.filterTable.Tekst) !== -1
          }
        );
        setPartneri(a);
      }

   },[props.filterTable]);

  // pokrećem samo jednom i uzimam partnere sa servera za izabranog komercijalistu
  useEffect(() => {
    props.onSpinerAktivan(true);

    const datumSnimanja = (localStorage.getItem('partneri_vrijeme')) ? localStorage.getItem('partneri_vrijeme') : '';
    const ws_connection = new WebSocket('ws://' + context.dns_server +'/');

    const initWs = () => {
         // šalji podatke kada se otvori socket
         // param3 : šifra komercijaliste
         ws_connection.onopen = function (event) {
           let ws_send = {'oib': context.firma_oib, 'poslovniprostor': context.poslovni_prostor, 'kasa': '1', 'opcija': 'web_komercijala_partneri', 'param1' : '', 'param2' : context.tip, 'param3' : context.komercijalista,'id': napraviId(), 'vrijeme_timestamp': datumSnimanja}
           ws_connection.send(JSON.stringify(ws_send), function ack (error) {
             console.log('Greška : ' + error);
             props.onBezServera({'bezServera':true, 'poruka':'Ne mogu se spojiti na Pakomov server!'});
           })
         }

         ws_connection.onmessage = function (event) {
           this.ws_podaci = event.data
           const messageArray = JSON.parse(this.ws_podaci);
           ws_connection.close();

           // obradi primljene podatke
           if (messageArray.id === 'nema') props.onBezServera({'bezServera':true, 'poruka':'Ne mogu se spojiti na Orbisov server!'});

           if (messageArray.osvjezi === 'da') {
             const start = Date.now();
             localStorage.setItem('partneri_podaci', JSON.stringify(messageArray.ODGOVOR1));
             localStorage.setItem('partneri_vrijeme', start.toString());
             setPartneriSvi(messageArray.ODGOVOR1);
             setPartneri(messageArray.ODGOVOR1);
           }
           // NOVO : ako se podaci osvježujune onda učitaj iz localStorage podatke
           else {
             var partneriPodaci = JSON.parse(localStorage.getItem('partneri_podaci'));
             if (partneriPodaci) {
               setPartneriSvi(partneriPodaci);
               setPartneri(partneriPodaci);
             }
           }
         }

         ws_connection.onclose = function (evt) {
          if (evt.wasClean) {
             console.log('ws zatvoren normalno');
           } else {
             console.log('ws greška u spajanju')
             props.onBezServera({'bezServera':true, 'poruka':'Greška u spajanju na server!'});
           }

           props.onSpinerAktivan(false);
         }

    };

    initWs();

    return () => {
      ws_connection.close();
    }

  }, []);

  function rowStyleFormat(row, rowIdx) {
    return { backgroundColor: row.konto_sifra === props.izabraniPartner.konto_sifra ? '#cbefc3' : 'white' };
  }

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      props.promjenaPartnera(row);
      //const a=[];
      //a.push(row);
      //setPartneri(a);
    }
  }

  const pagination = paginationFactory({
      page: 1,
      sizePerPage: 200,
      lastPageText: '>>',
      firstPageText: '<<',
      nextPageText: '>',
      prePageText: '<',
      showTotal: false,
      alwaysShowAllBtns: true,
      hideSizePerPage: true,
      onPageChange: function (page, sizePerPage) {
        //console.log('page', page);
        //console.log('sizePerPage', sizePerPage);
      },
      onSizePerPageChange: function (page, sizePerPage) {
        //console.log('page', page);
        //console.log('sizePerPage', sizePerPage);
      }
    });

  return (
    <Container>

      <>
      <Modal  animation={false} show={showModal.show} onHide={handleClose}>
       <Modal.Header closeButton>
         <Modal.Title>Upozorenje!</Modal.Title>
       </Modal.Header>
       <Modal.Body>Izabrani partner : {showModal.partner}</Modal.Body>
       <Modal.Footer>
         <Button variant="secondary" onClick={handleClose}>
           Close
         </Button>
       </Modal.Footer>
      </Modal>
    </>

      <BootstrapTable keyField='konto_sifra' data={ partneri } columns={ columnsPartneri } pagination={pagination} rowEvents={ tableRowEvents } rowStyle={rowStyleFormat}/>
    </Container>
  )
}

export default Tablepartneri
