import { useEffect, useState } from 'react';
import { Switch, Route } from "react-router-dom";
import Partneri from '../../components/Partneri/Partneri';
import Cjenik from '../../components/Cjenik/Cjenik';
import OtvoreneStavke from '../../components/OtvoreneStavke/OtvoreneStavke';
import RekapitulacijaArtikala from '../../components/RekapitulacijaArtikala/RekapitulacijaArtikala';
import PartnerContext from '../../context/PartnerContext/PartnerContext';

const Routes = () => {

  const [izabraniPartner, setIzabraniPartner] = useState({"konto_sifra":"", "naziv":"PARTNER NIJE IZABRAN"});
  const globPromjenaPartnera = (input) => {
     localStorage.setItem('izabraniPartner', JSON.stringify(input));
     setIzabraniPartner(input);
  }

  useEffect(() => {
    const ip = JSON.parse(localStorage.getItem('izabraniPartner'));
    if (ip) setIzabraniPartner(ip);
  },[]);

  return (
    <PartnerContext.Provider value={izabraniPartner}>
      <Switch>
        <Route exact path="/" render={(props) => (<Partneri {...props} globPromjenaPartnera= {globPromjenaPartnera} />)} />
        <Route exact path="/cjenik" render={(props) => (<Cjenik {...props} podaci = {{"opcija":"web_komercijala_cjenik", "storage_podaci" : "cjenik_1_podaci", "storage_vrijeme" : "cjenik_1_vrijeme"}} />)} />
        <Route exact path="/cjenik2" render={(props) => (<Cjenik {...props} podaci = {{"opcija":"web_komercijala_cjenik2", "storage_podaci" : "cjenik_2_podaci", "storage_vrijeme" : "cjenik_2_vrijeme"}} />)} />
        <Route exact path="/otvorenestavke" component={OtvoreneStavke} />
        <Route exact path="/rekart1" render={(props) => (<RekapitulacijaArtikala {...props} podaci = {{"opcija":"web_komercijala_rekart1"}} />)} />
        <Route exact path="/rekart2" render={(props) => (<RekapitulacijaArtikala {...props} podaci = {{"opcija":"web_komercijala_rekart2"}} />)} />
      </Switch>
    </PartnerContext.Provider>
    )
 };

  export default Routes;
