import { Container } from "react-bootstrap";

const Footer = (props) => {

  function logout() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
  }

  function osvjezi() {
    localStorage.clear();
    window.location.reload(true);
    window.location.href = '/';
  }

  return (
    <Container>
        <button type="button" className="btn btn-outline-primary m-1" onClick={logout}>Odjava</button>
        <button type="button" className="btn btn-outline-success m-1" onClick={osvjezi}>Osvježi</button>
    </Container>
  );

}

export default Footer;
