import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';

const Navigation = (props) => {

  const storeSelection = (selection) => sessionStorage.setItem('navSelection', selection);

  const navSelection = sessionStorage.getItem('navSelection');

  return (
    <Nav fill variant="pills" defaultActiveKey={navSelection} style={{fontSize: '0.8em'}} onSelect={storeSelection}>
      <Nav.Item>
        <Nav.Link as={NavLink} eventKey="partneri" to="/" exact>Partneri</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} eventKey="cjenik" to="/cjenik" >Cjenik(1)</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} eventKey="cjenik2" to="/cjenik2" >Cjenik(2)</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} eventKey="otvorenestavke" to="/otvorenestavke">Otv. stavke</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} eventKey="rekart1" to="/rekart1">Rek.art.(1)</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} eventKey="rekart2" to="/rekart2">Rek.art.(2)</Nav.Link>
      </Nav.Item>
    </Nav>
  )

}

export default Navigation
