import {useEffect, useState, useContext} from 'react';

import BootstrapTable from 'react-bootstrap-table-next';

import { Card, Container } from "react-bootstrap";
import AuthContext from '../../context/AuthContext/AuthContext';

const Tableotvorenestavke = (props) => {

  const columnsOtvoreneStavke = [
/*
    {
      dataField: 'broj_fakture',
      text: 'Broj fakt.',
      style: { fontSize: '12px', textAlign: 'left'},
      headerStyle: { width: '60px', overflowWrap: 'break-word', fontSize: '12px'}
    },
    {
      dataField: 'datum_dokumenta',
      text: 'Datum',
      style: { fontSize: '12px', textAlign: 'left'},
      headerStyle: { width: '75px', overflowWrap: 'break-word', fontSize: '12px'}
    },
    {
      dataField: 'valuta',
      text: 'Valuta',
      style: { fontSize: '12px', textAlign: 'left'},
      headerStyle: { width: '75px', overflowWrap: 'break-word', fontSize: '12px'}
    },
    {
      dataField: 'duguje',
      text: 'Duguje',
      style: { fontSize: '12px', textAlign: 'right'},
      headerStyle: { width: '80px', overflowWrap: 'break-word', fontSize: '12px', textAlign: 'right'}
    },
    {
      dataField: 'potrazuje',
      text: 'Potražuje',
      style: { fontSize: '12px', textAlign: 'right'},
      headerStyle: { width: '80px', overflowWrap: 'break-word', fontSize: '12px', textAlign: 'right'}
    },
    {
      dataField: 'calcsaldo',
      text: 'Saldo',
      style: { fontSize: '12px', textAlign: 'right'},
      headerStyle: { width: '80px', overflowWrap: 'break-word', fontSize: '12px', textAlign: 'right'}
    }
*/

{
  dataField: 'broj_fakture',
  text: 'Broj fakt.',
  style: { fontSize: '12px', textAlign: 'left'},
  headerStyle: { overflowWrap: 'break-word', fontSize: '12px'}
},
{
  dataField: 'datum_dokumenta',
  text: 'Datum',
  style: { fontSize: '12px', textAlign: 'left'},
  headerStyle: { overflowWrap: 'break-word', fontSize: '12px'}
},
{
  dataField: 'valuta',
  text: 'Valuta',
  style: { fontSize: '12px', textAlign: 'left'},
  headerStyle: { overflowWrap: 'break-word', fontSize: '12px'}
},
{
  dataField: 'duguje',
  text: 'Duguje',
  style: { fontSize: '12px', textAlign: 'right'},
  headerStyle: { overflowWrap: 'break-word', fontSize: '12px', textAlign: 'right'}
},
{
  dataField: 'potrazuje',
  text: 'Potražuje',
  style: { fontSize: '12px', textAlign: 'right'},
  headerStyle: { overflowWrap: 'break-word', fontSize: '12px', textAlign: 'right'}
},
{
  dataField: 'calcsaldo',
  text: 'Saldo',
  style: { fontSize: '12px', textAlign: 'right'},
  headerStyle: { overflowWrap: 'break-word', fontSize: '12px', textAlign: 'right'}
}

  ];

  const context = useContext(AuthContext);

  const [otvorenestavke, setOtvoreneStavke] = useState({"saldo":"0", "stavke":[]});

  const napraviId = () => {
      var text = ''
      var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      for (var i = 0; i < 6; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      return text
  }

  // pokrećem samo jednom i uzimam otvrene stavke sa servera
  useEffect(() => {
    props.onSpinerAktivan(true);

    // BITNO
    // ovako, kada radim refresh contextPartnera uzima tek kasnije i prvo radi ovo a tu moramu uzeti partnera koji je izabran iz localStorage
    var p = JSON.parse(localStorage.getItem('izabraniPartner'));
    if (!p) p = {"konto_sifra":"", "naziv":""};

    const ws_connection = new WebSocket('ws://' + context.dns_server +'/');
    const initWs = () => {
         // šalji podatke kada se otvori socket
         // param3 : šifra partnera
         ws_connection.onopen = function (event) {
           let ws_send = {'oib': context.firma_oib, 'poslovniprostor': context.poslovni_prostor, 'kasa': '1', 'opcija': 'web_komercijala_otvorene_stavke', 'param1' : '', 'param2' : '', 'param3' : p.konto_sifra,'id': napraviId()}
           ws_connection.send(JSON.stringify(ws_send), function ack (error) {
             console.log('Greška : ' + error);
             props.onBezServera({'bezServera':true, 'poruka':'Ne mogu se spojiti na Pakomov server!'});
           })
         }

         ws_connection.onmessage = function (event) {
           this.ws_podaci = event.data
           const messageArray = JSON.parse(this.ws_podaci);
           ws_connection.close();

           if (messageArray.id === 'nema') {
             props.onBezServera({'bezServera':true, 'poruka':'Ne mogu se spojiti na Orbisov server!'});
           }
           else
           {
             setOtvoreneStavke({...otvorenestavke, saldo:messageArray.saldo, stavke: messageArray.ODGOVOR1});
           }
         }

         ws_connection.onclose = function (evt) {
          if (evt.wasClean) {
             console.log('ws zatvoren normalno');
           } else {
             console.log('ws greška u spajanju');
             props.onBezServera({'bezServera':true, 'poruka':'Greška u spajanju na server!'});
           }
           props.onSpinerAktivan(false);
         }

    };

    initWs();

    return () => {
      ws_connection.close();
    }

  }, []);


  return (
    <div>
      <BootstrapTable keyField='jedinstveniBroj' data={ otvorenestavke.stavke } columns={ columnsOtvoreneStavke }/>
      <Card className="text-end" body><h6>SALDO : {otvorenestavke.saldo}</h6></Card>
    </div>
  )
}

export default Tableotvorenestavke;
