import { Row } from "react-bootstrap";

const IzabraniPartner = (props) => {

 return (
      <Row className="bg-light text-center">
        <p><strong>{props.izabraniPartner.naziv}</strong></p>
      </Row>
 )

}

export default IzabraniPartner
