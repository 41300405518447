import { useState, useContext } from 'react';
import { Modal, Button, Container, Form, FloatingLabel, Badge} from "react-bootstrap";
import AuthContext from '../../context/AuthContext/AuthContext';

const Searchbar = (props) => {

  const [tekst, setTekst] = useState('');
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const context = useContext(AuthContext);

  const handleTekstChange = event => {
    setTekst(event.target.value);
    props.onChange({Tekst: event.target.value});
  };

  const handleSubmit = event => {
    event.preventDefault();

    if ( !(tekst.length > 1)  ) {
      setShowModal(true);
    }
    else
    {
      props.onChange({Tekst: tekst});
    }
  };

  return (
  <Container>
    <Form onSubmit={handleSubmit} >

    <>
     <Modal  animation={false} show={showModal} onHide={handleClose}>
       <Modal.Header closeButton>
         <Modal.Title>Upozorenje!</Modal.Title>
       </Modal.Header>
       <Modal.Body>Unesite minimalno 2 znaka za pretraživanje!</Modal.Body>
       <Modal.Footer>
         <Button variant="secondary" onClick={handleClose}>
           Close
         </Button>
       </Modal.Footer>
     </Modal>
   </>


  <div className="bg-success bg-opacity-10 p-1 text-black">
    <Badge bg="primary">Prijavljen komercijalista : { context.komercijalista }</Badge>
    <FloatingLabel controlId="floatingText" label={props.tekstTrazenje}>
      <Form.Control
        autoFocus
        type="text"
        placeholder={props.tekstTrazenje}
        onChange={handleTekstChange}
        value={tekst}
        maxLength="20"
      />
      </FloatingLabel>
  </div>

  </Form>

</Container>
)

}

export default Searchbar
