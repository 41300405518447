import { BrowserRouter } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/Login/Login';
import useToken from './useToken';
import Navigation from './components/Navigation/Navigation'
import Routes from './components/Routes/Routes'
import AuthContext from './context/AuthContext/AuthContext'

function App() {

  const { token, setToken } = useToken();

  if(!token) {
    return (
      <Login
         setToken={ setToken }
      />
    )
  }

 return (
    <AuthContext.Provider value={token}>
      <BrowserRouter>
        <Navigation />
        <Routes />
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
