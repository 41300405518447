import {useEffect, useState, useContext} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from "react-bootstrap";
import AuthContext from '../../context/AuthContext/AuthContext';

const Tablecjenik = (props) => {

  const columnsArtikli = [
    {
      dataField: 'sifra_artikla',
      text: 'Šifra',
      style: { fontSize: '12px', textAlign: 'left'},
      headerStyle: { width: '60px', overflowWrap: 'break-word', fontSize: '12px'}
    },
    {
      dataField: 'naziv_artikla',
      text: 'Naziv',
      style: { fontSize: '13px', textAlign: 'left'},
      headerStyle: { width: '100px', overflowWrap: 'break-word', fontSize: '13px'}
    },
    {
      dataField: 'clcmpc',
      text: 'MPC',
      style: { fontSize: '10px', textAlign: 'right'},
      headerStyle: { width: '40px', textAlign: 'right',  overflowWrap: 'break-word', fontSize: '11px'}
    },
    {
      dataField: 'clcvpc',
      text: 'VPC',
      style: { fontSize: '10px', textAlign: 'right'},
      headerStyle: { width: '40px', textAlign: 'right',  overflowWrap: 'break-word', fontSize: '11px'}
    },
    {
      dataField: 'saldo',
      text: 'SALDO',
      style: { fontSize: '10px', textAlign: 'right'},
      headerStyle: { width: '40px', textAlign: 'right',  overflowWrap: 'break-word', fontSize: '11px'}
    }
  ];

  const contextAuth = useContext(AuthContext);

  const [artikli, setArtikli] = useState([]);
  const [artikliSvi, setArtikliSvi] = useState([]);


  const ws_opcija = props.podaci.opcija;
  const storage_podaci = props.podaci.storage_podaci;
  const storage_vrijeme = props.podaci.storage_vrijeme;

  const napraviId = () => {
      var text = ''
      var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      for (var i = 0; i < 6; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      return text
  }

    // pokrećem svaki put kada se ukuca tekst za pretraživanje
    useEffect(() => {
      // napravi kopiju originalnih podataka i filtriraj
      const artikliFilter = [...artikliSvi];
      if (typeof(props.filterTable) != "undefined") {
        var a =artikliFilter.filter(function (el)  {
          return el.naziv_artikla.toLowerCase().indexOf(props.filterTable.Tekst.toLowerCase()) !== -1 || el.sifra_artikla.indexOf(props.filterTable.Tekst) !== -1
          }
        );
        setArtikli(a);
      }

    },[props.filterTable]);

  // pokrećem kada se promjeni opcija pregleda cjenika 1 ili 2
  useEffect(() => {

    props.onSpinerAktivan(true);

    // NOVO : učitaj vrijeme kada su se snimili podaci, ako uopće postoji
    const datumSnimanja = (localStorage.getItem(storage_vrijeme)) ? localStorage.getItem(storage_vrijeme) : '';

    // BITNO
    // ovako, kada radim refresh contextPartnera uzima tek kasnije i prvo radi ovo a tu moramu uzeti partnera koji je izabran iz localStorage
    var p = JSON.parse(localStorage.getItem('izabraniPartner'));
    if (!p) p = {"konto_sifra":"", "naziv":""};

    const ws_connection = new WebSocket('ws://' + contextAuth.dns_server +'/');
    const initWs = () => {
         // šalji podatke kada se otvori socket
         // param3 : šifra partnera
         ws_connection.onopen = function (event) {
           let ws_send = {'oib': contextAuth.firma_oib, 'poslovniprostor': contextAuth.poslovni_prostor, 'kasa': '1', 'opcija': ws_opcija, 'param1' : '', 'param2' : '', 'param3' : p.konto_sifra, 'id': napraviId(), 'vrijeme_timestamp': datumSnimanja}
           ws_connection.send(JSON.stringify(ws_send), function ack (error) {
             console.log('Greška : ' + error);
             props.onBezServera({'bezServera':true, 'poruka':'Ne mogu se spojiti na Pakomov server!'});
           })
         }

         ws_connection.onmessage = function (event) {

           this.ws_podaci = event.data
           const messageArray = JSON.parse(this.ws_podaci);
           ws_connection.close();

           if (messageArray.id === 'nema') props.onBezServera({'bezServera':true, 'poruka':'Ne mogu se spojiti na Orbisov server!'});

           // NOVO : ako se podaci osvježuju onda radi kao do sada i snimi u localStorage podatke i vrijeme promjene
           if (messageArray.osvjezi === 'da') {
             const start = Date.now();
             localStorage.setItem(storage_podaci, JSON.stringify(messageArray.ODGOVOR1));
             localStorage.setItem(storage_vrijeme, start.toString());
             setArtikliSvi(messageArray.ODGOVOR1);
             setArtikli(messageArray.ODGOVOR1);
           }
           // NOVO : ako se podaci ne osvježuju onda učitaj iz localStorage podatke
           else {
             var cjenikPodaci = JSON.parse(localStorage.getItem(storage_podaci));
             setArtikliSvi(cjenikPodaci);
             setArtikli(cjenikPodaci);
           }

         }

         ws_connection.onclose = function (evt) {
          if (evt.wasClean) {
             console.log('ws zatvoren normalno');
           } else {
             console.log('ws greška u spajanju');
             props.onBezServera({'bezServera':true, 'poruka':'Greška u spajanju na server!'});
           }

           props.onSpinerAktivan(false);
         }

    };

    initWs();

    return () => {
      ws_connection.close();
    }

  }, [ws_opcija]);

  const pagination = paginationFactory({
      page: 1,
      sizePerPage: 200,
      lastPageText: '>>',
      firstPageText: '<<',
      nextPageText: '>',
      prePageText: '<',
      showTotal: false,
      alwaysShowAllBtns: true,
      hideSizePerPage: true,
    });

  return (
    <Container>
      <BootstrapTable keyField='sifra_artikla' data={ artikli } columns={ columnsArtikli } pagination={pagination} />
    </Container>
  )
}

export default Tablecjenik;
